// This file implements the "ducks" pattern, i.e. combines action constants,
// action creators and reducers in one file instead of having multiple files per
// function. As long as there are not too many values, this makes it nice and
// compact.

export const TOGGLE_LEFT = "TOGGLE_LEFT"
export const TOGGLE_RIGHT = "TOGGLE_RIGHT"

// Action constants

export const TOGGLEBUTTON_SELECT = 'TOGGLEBUTTON_SELECT';
export const TOGGLEBUTTON_RESET = 'TOGGLEBUTTON_RESET';

// Reducer

const initialState = {
};

// Action creators

export const selectButton = (groupId, val, label, value) => ({
  type: TOGGLEBUTTON_SELECT,
  groupId, val, label, value
})

export const resetToggle = (groupId) => ({
  type: TOGGLEBUTTON_RESET,
  groupId
})

// Reducer

// groupId: name of the button, e.g. unitToggle or winterToggle
// val/btn: TOGGLE_LEFT or TOGGLE_RIGHT
// label: display label (e.g. "°F" or "°C");
// value: internal differentiator (e.g. "imperial" or "metric")
const updateGroup = (slice={}, {val, label, value}) => (
  {
    ...slice,
    btn:val,
    label,
    value
  }
)

const toggleButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLEBUTTON_RESET:
      return {
        ...state,
        [action.groupId]: updateGroup(state[action.groupId], {val: undefined, label: undefined, value: undefined})
      }

    case TOGGLEBUTTON_SELECT:
      return {
        ...state,
        [action.groupId]: updateGroup(state[action.groupId], action)
      }

    default:
      return state

  }
}

export default toggleButtonReducer;



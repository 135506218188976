import { connect } from 'react-redux'
import ThreatFilterComponent from './ThreatFilterComponent'
import { toggleFilterView, applyFilters, setPage } from 'ThreatList/ThreatListDuck'
import {
  setLocation,
  setThreatFilter,
  resetForm,
  saveThreatFilter,
  loadThreatFilters,
  openModal,
  closeModal,
} from 'ThreatFilter/ThreatFilterDuck'
import { onboardingStep } from 'common/ducks/OnboardingDuck';
import { resetTiming } from 'common/components/TimingSelector/TimingSelectorDuck';
import { resetSlider } from 'common/components/FilterSlider/FilterSliderDuck';
import { resetCheckboxes } from 'common/components/ThreatTypeSelector/ThreatTypeSelectorDuck';
import { resetLocation } from 'common/components/LocationSelector/LocationSelectorDuck';
import { ACTIVE_WINTER_SITES } from "SiteFilter/SiteFilterDuck";

export const THREATFILTER = 'threatfilter';

const getWinterToggle = togglebutton => {
  const winterToggle = togglebutton["winterToggle"];
  return  !winterToggle || winterToggle.label === ACTIVE_WINTER_SITES
      ? "winter"
      : "nonwinter"
}

const getUnitToggle = togglebutton => {
  const unitToggle = togglebutton["unitToggle"];
  return unitToggle?.value
}

const mapStateToProps = ({threatfilter, filterslider, timingselector, threattypeselector, locationselector, login, togglebutton}) => ({
  userId: login.userId,
  isFresh: threatfilter.isFresh,
  showModal: threatfilter.showModal,
  error: threatfilter.error,
  locVal: locationselector[THREATFILTER] || undefined ,
  popSliderVal: filterslider[`${THREATFILTER}_pop`] && filterslider[`${THREATFILTER}_pop`].value,
  snowSliderVal: filterslider[`${THREATFILTER}_snow`] && filterslider[`${THREATFILTER}_snow`].value,
  iceSliderVal: filterslider[`${THREATFILTER}_ice`] && filterslider[`${THREATFILTER}_ice`].value,
  rainSliderVal: filterslider[`${THREATFILTER}_rain`] && filterslider[`${THREATFILTER}_rain`].value,
  windSliderVal: filterslider[`${THREATFILTER}_wind`] && filterslider[`${THREATFILTER}_wind`].value,
  timingVal: timingselector[THREATFILTER] && timingselector[THREATFILTER].timing,
  typeVal: threattypeselector[THREATFILTER] && threattypeselector[THREATFILTER].threatTypes,
  winterToggle: getWinterToggle(togglebutton),
  unitToggle: getUnitToggle(togglebutton),
})

const mapDispatchToProps = (dispatch) => ({
  toggleFilterView: () => {
    dispatch(toggleFilterView())
  },
  setLocation: (loc) => {
    dispatch(setLocation(loc))
  },
  setThreatFilter: (popSliderVal, snowSliderVal, iceSliderVal, rainSliderVal, windSliderVal, timingVal, locVal, typeVal) => {
    dispatch(toggleFilterView())
    dispatch(setThreatFilter({popSliderVal, snowSliderVal, iceSliderVal, rainSliderVal, windSliderVal, timingVal, locVal, typeVal}))
    dispatch(applyFilters())
    dispatch(setPage(0))
  },
  resetForm: () => {
    dispatch(resetForm());
    dispatch(resetTiming(THREATFILTER));
    dispatch(resetSlider(`${THREATFILTER}_pop`));
    dispatch(resetSlider(`${THREATFILTER}_snow`));
    dispatch(resetSlider(`${THREATFILTER}_ice`));
    dispatch(resetSlider(`${THREATFILTER}_rain`));
    dispatch(resetSlider(`${THREATFILTER}_wind`));
    dispatch(resetCheckboxes(THREATFILTER));
    dispatch(resetLocation(THREATFILTER));
    dispatch(setPage(0))
  },
  openModal: name => dispatch(openModal(name)),
  closeModal: () => dispatch(closeModal()),
  loadThreatFilters: (personId) => dispatch(loadThreatFilters(personId)),
  saveThreatFilter: (name, options, personId) => {
    dispatch(saveThreatFilter(name, options, personId));
    dispatch(onboardingStep({key: 'threatFilter', step: 3}))
  }
})

const ThreatFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatFilterComponent)

export default ThreatFilterContainer;

import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { canonicalPhone } from "common/helpers/helpers";

import {
  PERSONALDATA_SUBMIT,
  PERSONALDATA_SUCCESS,
  PERSONALDATA_FAILURE,
  AVATAR_GET_SUBMIT, AVATAR_GET_SUCCESS, AVATAR_GET_FAILURE,
  PERSONALDATA_AUTOREFRESH_SUBMIT, PERSONALDATA_AUTOREFRESH_SUCCESS, PERSONALDATA_AUTOREFRESH_FAILURE
} from './ProfileDuck'
import { UPDATE_UNIT_TOGGLE } from 'common/dataRetrieval/CompanyDuck';

import fetchApi from 'utils/fetchApi'

export function* postPersonalDataSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { id, username, email, title, 
      mobilePhone, officePhone, extension, noSmsNotification, autoRefresh, reportUnit } = action.payload;
    const authToken = yield select((state) => state.login.authToken);

    let url = `/Persons/${id}`;
    const payload = { username, email, title, 
      mobilePhone: canonicalPhone(mobilePhone), 
      officePhone: canonicalPhone(officePhone), 
      extension, noSmsNotification, autoRefresh , reportUnit };

    // call API
    const parsedJson = yield call(fetchApi, url, { payload, authToken, method: 'PATCH' })

    if (!parsedJson.error){
      yield put({ type: PERSONALDATA_SUCCESS, userObj: parsedJson })
      yield put({ type: UPDATE_UNIT_TOGGLE, preferredUnit: parsedJson.reportUnit });
    } else {
      // 'payload' is the redux-form-submit-saga object that is given wholesale to
      // redux-form's SubmissionError
      yield put({ type: PERSONALDATA_FAILURE,
        message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text,
        payload: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text
      })
    }

  } catch (error) {
    log.error('postPersonalDataSaga', error)
    yield put({
      type: PERSONALDATA_FAILURE,
      message: 'Network error.'
    })
  }
}

export function* postAutoRefreshSaga (action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const { userId,  autoRefresh } = action;
    const authToken = yield select((state) => state.login.authToken);

    let url = `/Persons/${userId}`;
    const payload = { autoRefresh };

    const parsedJson = yield call(fetchApi, url, { payload, authToken, method: 'PATCH' })

    if (!parsedJson.error){
      yield put({ type: PERSONALDATA_AUTOREFRESH_SUCCESS, userObj: parsedJson })
    } else {
      yield put({ type: PERSONALDATA_AUTOREFRESH_FAILURE,
        apiError: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text
      })
    }

  } catch (error) {
    log.error('postAutoRefreshSaga', error)
    yield put({
      type: PERSONALDATA_AUTOREFRESH_FAILURE,
      message: 'Network error.'
    })
  }
}


export function* fetchAvatar (action) {
  try {
    const login = yield select((state) => state.login);
    const authToken = login.authToken;
    const userId = action.userId;

    if (!userId){
      yield put({ type: AVATAR_GET_FAILURE,
        payload: 'User undefined'
      })
    } else {
      let url = `/Persons/${userId}/avatar`;
      const result = yield call(fetchApi, url, { authToken, method: 'GET' })

      if (!result.error && !result.notfound){
        if (result.status !== "Not found"){
          const objectURL = URL.createObjectURL(result);
          yield put({
            type: AVATAR_GET_SUCCESS,
            userId: userId,
            data: objectURL
          })
        } else {
          //log.debug('No avatar found for', userId)
          yield put({ type: AVATAR_GET_FAILURE,
            payload: 'No avatar image found.'
          })
        }
      } else {
        yield put({ type: AVATAR_GET_FAILURE,
          payload: result.error.text._error ? result.error.text._error : result.error.text
        })
      }
    }
  } catch (error) {
    log.error('fetchAvatar', error)
  }
}


// listen for actions of type USEREDIT_SUBMIT and use them
export default function* personalDataSaga () {
  yield takeEvery(PERSONALDATA_SUBMIT, postPersonalDataSaga);
  yield takeEvery(PERSONALDATA_AUTOREFRESH_SUBMIT, postAutoRefreshSaga);
  yield takeEvery(AVATAR_GET_SUBMIT, fetchAvatar);
}


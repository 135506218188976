import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Alert, Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import BusySpinner from 'common/components/BusySpinner';
import 'common/css/inputElements.css';
import "./ReportSettings.css";
import immutable from "object-path-immutable";
import { WwaCheckbox } from "common/components/WwaInputElements";

const roundToHundreth = (value) => Math.round((value<0?0:value) * 100) / 100

const CustomIntensityValueSelector = ({name, id, controlId, value, onChange, options, optionValues}) => {
  return (
    <FormGroup 
      controlId={controlId}
    >
      <FormLabel>
        <span className="ReportSettings__CustomReportLabel">
          {name}
        </span>
      </FormLabel>
      <div style={{display: "block"}}>
      <select
        id={id}
        onChange={onChange}
        className='wwa__select'
        value={value}
      >
        {options.map(( option, i ) => 
          <option
            className='SiteSel__option'
            key={i}
            value={optionValues[i]}
          >{option}
          </option>
        )}
      </select>
      </div>
    </FormGroup>
  )
}

const CustomReportNonwinterForm = props => {
  // N.B.: to simplify things, the single source of truth for snow/ice filter values is stored as inches and only displayed in metric units
  const metricUnits = props.reportUnit === 'metric';
  const maxTotalPrecip = metricUnits ? 500 * 2.54 : 500;
  let customReportInitialValues = {...props.customReportInitialValues};
  if(metricUnits){
    // customReportInitialValues['totalPrecipIN'] = Number(customReportInitialValues['threat']?.['totalPrecipIN'] * 2.54).toFixed(1);
    customReportInitialValues = immutable(customReportInitialValues).set("threat.totalPrecip", Number((customReportInitialValues['threat']?.['totalPrecipIN'] ?? 0) * 2.54).toFixed(1)).del("threat.totalPrecipIN").value();
    customReportInitialValues = immutable(customReportInitialValues).set("tropical.windSpeedMax", Number((customReportInitialValues['tropical']?.['windSpeedMaxMPH'] ?? 0) * 1.609344).toFixed(0)).del("tropical.windSpeedMaxMPH").value();
    customReportInitialValues = immutable(customReportInitialValues).set("tropical.windGust", Number((customReportInitialValues['tropical']?.['windGustMPH'] ?? 0) * 1.609344).toFixed(0)).del("tropical.windGustMPH").value();
    customReportInitialValues = immutable(customReportInitialValues).set("tropical.distanceFromCone", Number((customReportInitialValues['tropical']?.['distanceFromCone'] ?? 0) * 1.609344).toFixed(0)).value();
  }

  return (
    <div className="">
      <Formik
        initialValues={customReportInitialValues}
        validate={values => {
          let errors = {};
          if(values.name.length > 30) {
            errors.name = 'Max 30 characters'
          } 
          if(+values.threat?.totalPrecip > 500) {
            errors = immutable(errors).set("threat.totalPrecip", `Max ${maxTotalPrecip}`).value();
          } else if(+values.threat?.totalPrecip < 0) {
            errors = immutable(errors).set("threat.totalPrecip", "No negative").value()
          } 
          if(+values.tropical?.maxPop < 0) {
            errors = immutable(errors).set("threat.maxPop", "No negative").value()
          } 
          if(+values.tropical?.distanceFromCone < 0) {
            errors = immutable(errors).set("tropical.distanceFromCone", "No negative").value()
          } 
          if(+values.tropical?.windSpeedMax < 0) {
            errors = immutable(errors).set("tropical.windSpeedMax", "No negative").value()
          } 
          if(+values.tropical?.windGustMPH < 0) {
            errors = immutable(errors).set("tropical.windGust", "No negative").value()
          } 
          return errors;
        }}

        onSubmit={(values, { setSubmitting }) => {
          let {name, ...formValues} = values;
          if(metricUnits){
            formValues = immutable(formValues).set("threat.totalPrecipIN", Number((formValues['threat']?.['totalPrecip'] ?? 0) / 2.54).toFixed(2)).del("threat.totalPrecip").value();
            formValues = immutable(formValues).set("tropical.windSpeedMaxMPH", Number((formValues['tropical']?.['windSpeedMax'] ?? 0) / 1.609344).toFixed(2)).del("tropical.windSpeedMax").value();
            formValues = immutable(formValues).set("tropical.windGustMPH", Number((formValues['tropical']?.['windGust'] ?? 0) / 1.609344).toFixed(2)).del("tropical.windGust").value();
            formValues = immutable(formValues).set("tropical.distanceFromCone", Number((formValues['tropical']?.['distanceFromCone'] ?? 0) / 1.609344).toFixed(2)).value();
          }
          const id = customReportInitialValues.id; // use stable ID, reset to empty mustn't change this
          props.saveCustomReportFilterValues(props.companyId, formValues, name || undefined, id);
        }}
      >

        {({ submitForm, values, errors, touched, handleChange, handleBlur, resetForm, dirty, setFieldValue }) => (
          <Form>
            <FormGroup 
              controlId="formValidationError1" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  Name (optional){" "}
                </span>
              </FormLabel>
              <FormControl 
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                {(props.apiError || {}).name}
                <ErrorMessage name="name"/>
              </div>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError4" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  POP (probability of precipitation) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="threat.maxPop"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.threat?.maxPop}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="threat.maxPop"/>
              </div>
            </FormGroup>

            <FormGroup 
              controlId="formValidationError2" 
            >
              <FormLabel>
                <span className="ReportSettings__CustomReportLabel">
                  And Total Precip ({metricUnits ? 'mm' : 'inches'}) &ge;{" "}
                </span>
              </FormLabel>
              <FormControl 
                type="number"
                name="threat.totalPrecip"
                onChange={e => setFieldValue("threat.totalPrecip", roundToHundreth(+e.target.value)) }
                onBlur={handleBlur}
                value={values.threat?.totalPrecip}
              />
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                <ErrorMessage name="threat.totalPrecip"/>
              </div>
            </FormGroup>


            <div>
              <span className="ReportSettings__tabtitle">
                Daily Weather by Site Tab
              </span>
              {/* When "Reset" button is pressed, change ThreatTypeSelector's key to reinitialize it.
                Initial values: on first render: show previously saved values; on reset: empty values.
              */}
              {/*for Daily Threat tab*/}
              <div className="wwa_checkbox_and_label">
                <WwaCheckbox 
                  id={"threat.thunderStorm"} 
                  name={"threat.thunderStorm"} 
                  onChange={e => setFieldValue("threat.thunderStorm", e.target.checked)}
                  checked={values.threat?.thunderStorm ? true : false} />
                <span className="wwa_checkbox_label">Thunderstorms</span>
              </div>
              <div className="wwa_checkbox_and_label">
                <WwaCheckbox 
                  id={"threat.fog"} 
                  name={"threat.fog"} 
                  onChange={e => setFieldValue("threat.fog", e.target.checked)}
                  checked={values.threat?.fog ? true : false} />
                <span className="wwa_checkbox_label">Fog</span>
              </div>
              <div style={{height: "1em"}} />
              <CustomIntensityValueSelector 
                name={"Flood"} 
                id={"threat.flood"} 
                value={values.threat.flood} 
                onChange={handleChange} 
                controlId={"formValidationError5"}
                options={["---", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
                optionValues={["", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
              />
              <CustomIntensityValueSelector 
                name={"Damaging Winds"} 
                id={"threat.damagingWind"} 
                value={values.threat.damagingWind} 
                onChange={handleChange} 
                controlId={"formValidationError6"}
                options={["---", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only", "Any High Winds"]}
                optionValues={["", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only", "Any High Winds"]}
              />
              <CustomIntensityValueSelector 
                name={"Tornado"} 
                id={"threat.torn"} 
                value={values.threat.torn} 
                onChange={handleChange} 
                controlId={"formValidationError7"}
                options={["---", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
                optionValues={["", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
              />
              <CustomIntensityValueSelector 
                name={"Hail"} 
                id={"threat.hail"} 
                value={values.threat.hail} 
                onChange={handleChange} 
                controlId={"formValidationError9"}
                options={["---", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
                optionValues={["", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
              />
              <CustomIntensityValueSelector 
                name={"Severe"} 
                id={"threat.severe"} 
                value={values.threat.severe} 
                onChange={handleChange} 
                controlId={"formValidationError8"}
                options={["---", "Marginal or Higher", "Slight or Higher", "Enhanced or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
                optionValues={["", "Marginal or Higher", "Slight or Higher", "Enhanced or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
              />

              {/*for Tropical tab*/}
              <span className="ReportSettings__tabtitle">
                Tropical - Hurricane Report Tab
              </span>
              <div className="wwa_checkbox_and_label">
                <WwaCheckbox 
                  id={"tropical.isInErrorCone"} 
                  name={"tropical.isInErrorCone"} 
                  onChange={e => setFieldValue("tropical.isInErrorCone", e.target.checked)}
                  checked={values.tropical?.isInErrorCone ? true : false} />
                <span className="wwa_checkbox_label">Is in Error Cone</span>
              </div>

              <FormGroup 
                controlId="formValidationError11" 
              >
                <FormLabel>
                  <span className="ReportSettings__CustomReportLabel">
                    Any Site within “X” {metricUnits ? 'Kilometers' : 'Miles'} (whole number)
                  </span>
                </FormLabel>
                <FormControl 
                  type="number"
                  name="tropical.distanceFromCone"
                  onChange={e => setFieldValue("tropical.distanceFromCone", Math.round(+e.target.value)) }
                  onBlur={handleBlur}
                  value={values.tropical?.distanceFromCone}
                />
                <div style={{color:'#a94442', marginTop:'0.5em'}}>
                  <ErrorMessage name="tropical.distanceFromCone"/>
                </div>
              </FormGroup>
              <CustomIntensityValueSelector 
                name={"Forecasted Storm Category"} 
                id={"tropical.forecastStormCat"} 
                value={values.tropical.forecastStormCat} 
                onChange={handleChange} 
                controlId={"formValidationError10"}
                options={["---", "Tropical Storm", "Category 1 or Higher", "Category 2 or Higher", "Category 3 or Higher", "Category 4 or Higher", "Category 5 or Higher"]}
                optionValues={["", "Tropical Storm", "Category 1 or Higher", "Category 2 or Higher", "Category 3 or Higher", "Category 4 or Higher", "Category 5 or Higher"]}
              />
              <FormGroup 
                controlId="formValidationError12" 
              >
                <FormLabel>
                  <span className="ReportSettings__CustomReportLabel">
                    Maximum Sustained Wind &ge; (enter in mph)
                  </span>
                </FormLabel>
                <FormControl 
                  type="number"
                  name="tropical.windSpeedMax"
                  onChange={e => setFieldValue("tropical.windSpeedMax", Math.round(+e.target.value)) }
                  onBlur={handleBlur}
                  value={values.tropical?.windSpeedMax ?? 0}
                />
                <div style={{color:'#a94442', marginTop:'0.5em'}}>
                  <ErrorMessage name="tropical.windSpeedMax"/>
                </div>
              </FormGroup>
              <FormGroup 
                controlId="formValidationError13" 
              >
                <FormLabel>
                  <span className="ReportSettings__CustomReportLabel">
                    Maximum Wind Gust &ge; (enter in mph) 
                  </span>
                </FormLabel>
                <FormControl 
                  type="number"
                  name="tropical.windGust"
                  onChange={e => setFieldValue("tropical.windGust", Math.round(+e.target.value)) }
                  onBlur={handleBlur}
                  value={values.tropical?.windGust ?? 0}
                />
                <div style={{color:'#a94442', marginTop:'0.5em'}}>
                  <ErrorMessage name="tropical.windGust"/>
                </div>
              </FormGroup>
              <CustomIntensityValueSelector 
                name={"Excessive Rainfall Outlook"} 
                id={"tropical.excessiveRainfallOutlook"} 
                value={values.tropical.excessiveRainfallOutlook} 
                onChange={handleChange} 
                controlId={"formValidationError10"}
                options={["---", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
                optionValues={["", "Low or Higher", "Moderate or Higher", "High or Higher", "Very High Only"]}
              />

            </div>

            {props.isSavedCustomReportsSuccess && (
              <Alert variant="success">
                Values saved successfully!
               </Alert>
            )}
            {props.apiError && (typeof props.apiError === "string") && 
              <div style={{color:'#a94442', marginTop:'0.5em'}}>
                {props.apiError}
              </div>
            }
            <div className="ReportSettings_Buttons">
              <Button variant="success" onClick={submitForm} disabled={props.isFetching}>
                {props.isFetching ? (
                  <span>
                    <BusySpinner inButton={true} />Saving
                  </span>
                ) : (
                  'Save'
                )}
              </Button>
              {/* Reset to previously saved values */}
              <Button 
                variant="outline-secondary" 
                onClick={() => {
                  resetForm({values: customReportInitialValues});
                }} disabled={props.isFetching}>
                Reset
              </Button>
              {/* Reset to empty values */}
              <Button 
                variant="outline-secondary" 
                onClick={() => {
                  resetForm({values: props.emptyInitialValues});
                }} disabled={props.isFetching}>
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};

export default CustomReportNonwinterForm;

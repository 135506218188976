import FilterSlider from './FilterSliderContainer';
import { percentToIn } from './FilterSlider'

export const INCHES10 = '10';
export const INCHES2 = '2';
export const INCHES6 = '6';
export const CM25 = '25';
export const MM60 = '60';
export const MM150 = '150';
export const MPH50 = '50';
export const KPH80 = '80';
export { percentToIn }

export default FilterSlider


import log from "loglevel";
import { put, takeEvery, call, select } from "redux-saga/effects";

import {
  SITEADD_SUBMIT,
  SITEADD_SUCCESS,
  SITEADD_FAILURE
} from "./SiteAddDuck";
import fetchApi from "utils/fetchApi";

export function* fetchSiteAddSaga(action) {
  try {
    // redux-form-submit-saga injects 'payload' into action
    const {
      name,
      address1,
      address2,
      state,
      city,
      zipCode,
      companyId,
      latitude,
      longitude,
      winter,
      nonwinter,
      country,
    } = action.payload;
    const url = "/Sites";
    const payload = {
      name,
      address1,
      address2,
      state,
      city,
      zipCode,
      companyId,
      country,
      types: []
    };
    if (winter) payload.types.push('winter');
    if (nonwinter) payload.types.push('nonwinter');

    if (latitude && longitude)
      payload.latLng = { lat: latitude, lng: longitude };
    const authToken = yield select(state => state.login.authToken);

    const parsedJson = yield call(fetchApi, url, { payload, authToken });

      console.log("siteaddSaga parsedJson=", parsedJson);
    if (!parsedJson.error) {
      yield put({ type: SITEADD_SUCCESS, siteObj: parsedJson, name });
      yield put({ type: "@@redux-form/RESET", meta: { form: "SiteAddForm" } });
    } else {
      yield put({
        type: SITEADD_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text,
        payload: parsedJson.error.text
      });
    }
  } catch (error) {
    log.error("fetchSiteAddSaga error", error);
    yield put({
      type: SITEADD_FAILURE,
      message: "Network error."
    });
  }
}

// listen for actions of type SITEADD_SUBMIT and use them
export default function* siteaddSaga() {
  yield takeEvery(SITEADD_SUBMIT, fetchSiteAddSaga);
}

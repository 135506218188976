import React from 'react';
import { Button } from 'react-bootstrap';
import deleteIcon from 'images/dashboard/watchlist-delete.svg'
import { displayFormat } from "./ReportSettings";
import './ReportSettings.css';

export const MAX_ENTRIES = 5;
export const TMPIDPREF = 'TMPID-'

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

class ReportSettingsCustomiser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportSettings: props.reportSettings,
      tmpid: 0
    }
  }

  // contract for this.state.reportSettings:
  // 1. there must not be more than MAX_ENTRIES entries in this array
  // 2. all "hour" fields in the array's objects must be unique

  // find a currently unused hour slot
  getNextHour = () => {
    for (let i = 1; i < 24; i++) {
      if (!this.state.reportSettings.find(r => r.hour === i))
        return i
    }
  };

  // new entry with previously unused "hour"
  defaultEntry = () => ({
    hour: this.getNextHour(),
    reportType: this.props.reportType,
    companyId: this.props.companyId,
    id: `${TMPIDPREF}${this.state.tmpid}` // temporary ID that will be removed before POSTing
  });

  addRow = () => {
    if (this.state.reportSettings.length < MAX_ENTRIES)
      this.setState(state => ({
        reportSettings: [...state.reportSettings, this.defaultEntry()],
        tmpid: state.tmpid+1
      }))
  };

  deleteRow = id => this.state.reportSettings.length > 1 &&
    this.setState(state => ({
      reportSettings: state.reportSettings.filter(rs => rs.id !== id)
    }));

  // update internal state to reflect hour change of existing object denoted by database ID or temporary ID
  handleHourChange = (e, rs) => {
    const newHour = Number(e.target.value)
    const newReportSettings = this.state.reportSettings.map(r => r.id === rs.id ? {...r, hour: newHour} : r)
    this.setState({reportSettings: newReportSettings})
  }

  render() {
    const {onSave} = this.props;

    return (
      <td>
        <div>
          {this.state.reportSettings.map(rs => <div key={rs.id}>
              <select
                className='wwa__select'
                style={{marginBottom: '12px'}}
                onChange={(e) => this.handleHourChange(e, rs)}
                value={rs.hour}
              >

                {hours
                  .filter(h => h === rs.hour || !this.state.reportSettings.some(rs1 => h === rs1.hour))
                  .map(h =>
                    <option key={h}
                            value={h}
                    >
                      {displayFormat(h, this.props.companyTimezone)}
                    </option>
                  )}
              </select>

              {this.state.reportSettings.length > 1 &&
              <img src={deleteIcon} alt='delete'
                   onClick={() => this.deleteRow(rs.id)}
                   style={{cursor: 'pointer', marginLeft: '12px'}}
              />
              }
            </div>
          )}
        </div>
        <span className='ReportSettings__button'>
          <Button
            variant="outline-dark"
            onClick={this.addRow}>
            Add more
          </Button>
        </span>

        <span className='ReportSettings__button'>
          <Button
            variant="outline-dark"
            onClick={this.props.onCancel}>
            Cancel
          </Button>
        </span>

        <span className='ReportSettings__button'>
          <Button
            variant='primary'
            onClick={() => onSave(this.state.reportSettings)}>
            Save
          </Button>
        </span>

      </td>
    )
  }
}

export default ReportSettingsCustomiser

import log from 'loglevel';
import fetchApi from 'utils/fetchApi'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { REPORTS_EXECUTE_FAILURE, REPORTS_EXECUTE_SUBMIT, REPORTS_EXECUTE_SUCCESS } from "./ReportsExecuteDuck";

function* executeReports({
  companyId,
  reportTypes,
  targetUserType,
  personIds,
  thirdPartyUserIds,
  operationHour,
  overrideMailDrop
}) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const subscription = yield select(state => Object.values(state.subscriptions).find(s => s.companyId === companyId));
    const companyTypes = subscription?.types;
    let uniqReportTypes = [];
    if(reportTypes){
      let augmentedReportTypes = [ ...reportTypes ];
      if(reportTypes.includes("company")){
        if(companyTypes?.includes("winter")) augmentedReportTypes.push("company");
        if(companyTypes?.includes("nonwinter")) augmentedReportTypes.push("dailyThreat");
      }
      if(reportTypes.includes("companyWithSites")){
        if(companyTypes?.includes("winter")) augmentedReportTypes.push("companyWithSites");
        if(companyTypes?.includes("nonwinter")) augmentedReportTypes.push("dailyThreatWithSites");
      }
      if(reportTypes.includes("customReport")){
        if(companyTypes?.includes("winter")) augmentedReportTypes.push("customReport");
        if(companyTypes?.includes("nonwinter")) augmentedReportTypes.push("customReportNonwinter");
      }
      uniqReportTypes = [...new Set(augmentedReportTypes)];
    }
    const payload = {
      companyId,
      reportTypes: uniqReportTypes,
      targetUserType,
      personIds,
      thirdPartyUserIds
    };

    let url 
    if(operationHour) {
      payload.operationHour = operationHour;
      payload.overrideMailDrop = overrideMailDrop;
      url = '/Reports/force-resend-all';
    } else {
      url = companyId
        ? "/Reports/force-execute"
        : "/Reports/force-execute-all";
    }

    const parsedJson = yield call(fetchApi, url, {
      authToken,
      prefix: "",
      payload
    });

    if (!parsedJson.error) {
      yield put({
        type: REPORTS_EXECUTE_SUCCESS
      });
    } else {
      yield put({
        type: REPORTS_EXECUTE_FAILURE,
        message: parsedJson.error.text._error
          ? parsedJson.error.text._error
          : parsedJson.error.text
      });
    }
  } catch (error) {
    log.error("executeReports error", error);
  }
}

export default function* reportsExecuteSaga() {
  yield takeEvery(REPORTS_EXECUTE_SUBMIT, executeReports)
}

import { combineReducers } from 'redux';
import login from 'Login/LoginDuck';
import { mapAnimation, mapControls, locationWeather, selectedSite, mapSiteFilter} from 'Map/MapReducers'
import forgotpass from 'ForgotPass/ForgotPassDuck';
import resetpass from 'ResetPass/ResetPassDuck';
import verifyPerson from 'VerifyPerson/VerifyPersonDuck'
import header from 'Header/HeaderDuck';
import threatDetails from 'ThreatDetails/ThreatDetailsDuck'
import threatlist from 'ThreatList/ThreatListDuck';
import threatfilter from 'ThreatFilter/ThreatFilterDuck';
import threattile from 'ThreatTile/ThreatTileDuck';
import sitefilter from 'SiteFilter/SiteFilterDuck';
import togglebutton from 'common/components/ToggleButton/ToggleButtonDuck';
import timingselector from 'common/components/TimingSelector/TimingSelectorDuck';
import selector from 'common/components/Selector/SelectorDuck';
import filterslider from 'common/components/FilterSlider/FilterSliderDuck';
import threattypeselector from 'common/components/ThreatTypeSelector/ThreatTypeSelectorDuck';
import siteselector from 'common/components/SiteSelector/SiteSelectorDuck';
import advisorytypeselector from 'common/components/AdvisoryTypeSelector/AdvisoryTypeSelectorDuck';
import locationselector from 'common/components/LocationSelector/LocationSelectorDuck';
import companySelector from 'common/components/CompanySelector/CompanySelectorDuck';
import searchbox from 'common/components/SearchBox/SearchBoxDuck';
import notificationfilter from 'NotificationFilter/NotificationFilterDuck';
import notificationlist from 'NotificationList/NotificationListDuck';
import notificationtile from 'NotificationTile/NotificationTileDuck';
import { sites, threats, events, dataLoadingStatus, statesandcities, notifications,
        forecasts, observations, companySites, advisoryTypes, sitesBasicInformation} from 'common/dataRetrieval/DataDuck';
import companies, { companyInfos, companyPersons } from 'common/dataRetrieval/CompanyDuck';
import { sitePersons, mySites } from 'common/dataRetrieval/SiteDuck';
import persons, {personsMeta} from 'common/dataRetrieval/PersonDuck';
import subscriptions from 'common/dataRetrieval/SubscriptionDuck';
import addCompany from 'Admin/CompanyAdd/CompanyAddDuck';
import addSite from 'Admin/SiteAdd/SiteAddDuck';
import editCompany from 'Admin/CompanyEdit/CompanyEditDuck';
import editSite from 'Admin/SiteEdit/SiteEditDuck';
import invitePerson from 'Admin/InvitePerson/InvitePersonDuck';
import subscriptionselector from 'common/components/SubscriptionSelector/SubscriptionSelectorDuck';
import fileUpload from 'common/components/FileUploadDialog/FileUploadDuck'
import companiesPage from 'Admin/CompaniesManage/CompaniesManageDuck';
import superAdminPage from 'Admin/SuperAdminManage/SuperAdminManageDuck';
import sitesManage from 'Admin/SitesManage/SitesManageDuck';
import siteAdmin from 'Admin/SiteAdmin/SiteAdminDuck';
import siteUsersManage from 'Admin/SiteUsersManage/SiteUsersManageDuck';
import usersManage from 'Admin/UsersManage/UsersManageDuck';
import userAssign from 'Admin/SiteUsersManage/UserAssign/UserAssignDuck';
import bulkSiteAssignment from 'Admin/BulkSiteAssignment/BulkSiteAssignmentDuck';
import userUnassign from 'Admin/SiteUsersManage/UserUnassign/UserUnassignDuck';
import userReportSubscriptions from 'common/dataRetrieval/ReportsDuck'
import personalData from 'common/components/Profile/ProfileDuck';
import pollNewData from 'common/ducks/pollNewDataDuck';
import imageUpload from 'common/ducks/imageUploadDuck';
import onboarding from 'common/ducks/OnboardingDuck';
import reportPickup from 'SiteReportPickup/SiteReportPickupDuck';
import contact from 'Marketing/ContactForm/ContactDuck';
import reportSettings from 'Admin/ReportSettings/ReportSettingsDuck';
import reportsExecute from 'Admin/ReportsExecute/ReportsExecuteDuck';
import apiPackages from 'common/ApiPackage/ApiPackageDuck';
import apiApps from 'common/dataRetrieval/ApiAppsDuck';
import apiStats from 'common/dataRetrieval/ApiStatsDuck';
import devHeader from 'Developer/Header/HeaderDuck';
import supportEmailForm from 'common/components/SupportEmailForm/SupportEmailFormDuck';
import apiStatus from 'common/components/ApiStatus/ApiStatusDuck';
import logViewer from 'Admin/LogViewer/LogViewerDuck';
import collapsible from 'common/components/Collapsible/CollapsibleDuck';
import eventTotals from 'EventTotals/EventTotalsDuck';
import quickFilters from 'QuickFilter/QuickFilterDuck';
import freezeScheduler from 'Admin/FreezeScheduler/FreezeSchedulerDuck';
import activateTrialCompany from 'Admin/ActivateTrialCompany/ActivateTrialCompanyDuck';
import callToAction from 'common/dataRetrieval/CallToActionDuck'
import bulkFileUpload from 'common/components/BulkUserUploadDialog/BulkUserFileUploadDuck'

/*
 * Collect all reducers from components here: each time a Redux enabled component
 * is added, update the allReducers.
 */

const allReducers = combineReducers({
  activateTrialCompany,
  addCompany,
  addSite,
  advisoryTypes,
  advisorytypeselector,
  apiApps,
  apiPackages,
  apiStats,
  apiStatus,
  bulkSiteAssignment,
  callToAction,
  collapsible,
  companies,
  companiesPage,
  companyInfos,
  companyPersons,
  companySelector,
  companySites,
  contact,
  dataLoadingStatus,
  devHeader,
  editCompany,
  editSite,
  events,
  eventTotals,
  fileUpload,
  filterslider,
  forecasts,
  forgotpass,
  freezeScheduler,
  header,
  imageUpload,
  invitePerson,
  locationWeather,
  locationselector,
  login,
  logViewer,
  mapAnimation,
  mapControls,
  mapSiteFilter,
  mySites,
  notificationfilter,
  notificationlist,
  notifications,
  notificationtile,
  observations,
  onboarding,
  personalData,
  persons,
  personsMeta,
  pollNewData,
  quickFilters,
  reportPickup,
  reportSettings,
  reportsExecute,
  resetpass,
  searchbox,
  selector,
  selectedSite,
  siteAdmin,
  sitePersons,
  siteUsersManage,
  sitefilter,
  sites,
  sitesBasicInformation,
  sitesManage,
  siteselector,
  statesandcities,
  subscriptions,
  subscriptionselector,
  superAdminPage,
  supportEmailForm,
  threatDetails,
  threatfilter,
  threatlist,
  threats,
  threattile,
  threattypeselector,
  timingselector,
  togglebutton,
  userAssign,
  userReportSubscriptions,
  userUnassign,
  usersManage,
  verifyPerson,
  bulkFileUpload
});

/*
 * Clear Redux store on successful Logout.
 */
const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS' || action.type === 'LOGOUT_FAILURE') {
    state = undefined
  }

  return allReducers(state, action)
}

export default rootReducer;

/*
 * Validation functions for redux-form <Field /> components.
 * see https://redux-form.com/7.0.4/examples/fieldlevelvalidation/
 */
const isEmpty = value => value === undefined || value === null || value === '';
export const required = value => (value || typeof value === 'number' ? undefined : 'Required')

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const positiveOrMinusOne = value => value && Number(value) < -1 ? 'Must be -1 or over' : undefined
export const startsWithAlphanumeric = value => value && !/^[A-zÀ-ÿ\d].*/.test(value.trim()) ? 'Must start with alphanumeric character' : undefined;

const isValidIp = ip => /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);
export const isIpList = value => (value &&
    (typeof value === 'string' || value instanceof String) &&
    (value.trim() === '*' ||
      isValidIp(value.trim()) ||
      value.split(',')
      .filter(ip => isValidIp(ip.trim()))
      .length === value.split(',').length
    )
  ) ? undefined
    : 'Must be a list of IP addresses, separated by commas'

export function phone(value) {
  if (!isEmpty(value) && !/^[0-9]{3}-{0,1}[0-9]{3}-{0,1}[0-9]{4}$/.test(value.trim())) {
    return 'Invalid phone number';
  }
}

export function extension(value) {
  if (!isEmpty(value) && !/^[0-9-]{1,5}$/.test(value.trim())) {
    return 'Invalid phone extension';
  }
}

export function zipCode(value, allValues) {
  if (!isEmpty(value) &&
    (
      (allValues?.country === "CA" && !/^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/.test(value.trim())) ||
      ((allValues?.country === "US" || !allValues?.country) && !/^[0-9-]{1,5}$/.test(value.trim()))
    )
) {
    return 'Invalid zip code';
  }
}

const matchRange = (v, min, max) => !isNaN(v) && v >= min && v <= max

export function latitude (value) {
  if (!isEmpty(value) && !matchRange(value, -90, 90))
    return 'Invalid latitude'
}

export function longitude (value) {
  if (!isEmpty(value) && !matchRange(value, -180, 180))
    return 'Invalid longitude'
}

export function validState (value) {
  if (value === 'Select state') return 'State must be selected'
}

export const maxLength10 = max => maxLength(10)
export const maxLength20 = maxLength(20)
export const maxLength30 = maxLength(30)
export const maxLength50 = maxLength(50)
export const maxLength72 = maxLength(72)
export const maxLength100 = maxLength(100)


export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value.trim())) {
    return 'Email invalid';
  }
}

export function winterTypeValid(value, allValues) {
  if (!(allValues['nonwinter'] || allValues['winter'])){
    return "Either Winter or Non-Winter must be checked";
  }
}

// react-final-form uses a single validation function on a <Field> instead of
// an array of functions like redux-form did
export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce((error, validator) => error || validator(value, allValues), undefined);


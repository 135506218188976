
import {addFormSubmitSagaTo} from 'redux-form-submit-saga';
import {watchMap} from 'Map/MapSagas';
import loginSaga, {authExpiredSaga} from 'Login/LoginSagas'
import logoutSaga from 'Logout/LogoutSagas'
import forgotPassSaga from 'ForgotPass/ForgotPassSagas'
import resetPassSaga from 'ResetPass/ResetPassSagas'
import verifyPersonSaga from 'VerifyPerson/VerifyPersonSagas'
import dataSaga from 'common/dataRetrieval/DataSagas'
import companies from 'common/dataRetrieval/CompanySagas'
import persons from 'common/dataRetrieval/PersonSagas'
import subscriptions from 'common/dataRetrieval/SubscriptionSagas'
import siteFilterSaga from 'ThreatList/ThreatListSagas'
import notificationFilterSaga from 'NotificationList/NotificationListSagas'
import addCompanySaga from 'Admin/CompanyAdd/CompanyAddSagas'
import editCompanySaga from 'Admin/CompanyEdit/CompanyEditSagas'
import addSiteSaga from 'Admin/SiteAdd/SiteAddSagas'
import editSiteSaga from 'Admin/SiteEdit/SiteEditSagas'
import invitePersonSaga from 'Admin/InvitePerson/InvitePersonSagas'
import siteSagas from 'common/dataRetrieval/SiteSagas'
import userAssignSaga from 'Admin/SiteUsersManage/UserAssign/UserAssignSagas'
import userUnassignSaga from 'Admin/SiteUsersManage/UserUnassign/UserUnassignSagas'
import userEditSaga from 'Admin/UsersManage/UserEdit/UserEditSagas'
import personalDataSaga from 'common/components/Profile/ProfileSagas'
import imageUploadSagas from 'common/sagas/imageUploadSagas'
import siteAdminSaga from 'Admin/SiteAdmin/SiteAdminSagas'
import reportSubscriptionSaga from 'common/dataRetrieval/ReportsSagas'
import ownReportSubscriptionSaga from 'common/components/Profile/ReportSubscriptionSagas'
import fileUploadSagas from 'common/components/FileUploadDialog/FileUploadSagas'
import pollNewDataSagas from 'common/sagas/pollNewDataSagas'
import serverSagas from 'common/sagas/serverSagas'
import siteReportPickup from 'SiteReportPickup/SiteReportPickupSagas'
import contactSagas from 'Marketing/ContactForm/ContactSagas'
import threatFilterSaga from 'ThreatFilter/ThreatFilterSagas'
import reportSettingsSaga from "Admin/ReportSettings/ReportSettingsSagas";
import reportsExecuteSaga from "Admin/ReportsExecute/ReportsExecuteSagas";
import apiPackageSagas from "common/ApiPackage/ApiPackageSagas";
import apiAppsSagas from "common/dataRetrieval/ApiAppsSagas";
import apiStatsSagas from "common/dataRetrieval/ApiStatsSagas";
import supportEmailFormSagas from 'common/components/SupportEmailForm/SupportEmailFormSagas';
import apiStatus from 'common/components/ApiStatus/ApiStatusSagas';
import logViewerSagas from 'common/sagas/logViewerSagas';
import freezeSchedulerSagas from 'Admin/FreezeScheduler/FreezeSchedulerSagas';
import activateTrialCompanySaga from 'Admin/ActivateTrialCompany/ActivateTrialCompanySaga';
import onboardingSagas from 'common/sagas/onboardingSagas';
import callToActionSagas from "common/dataRetrieval/CallToActionSagas";
import bulkUserFileUploadSagas from 'common/components/BulkUserUploadDialog/BulkUserFileUploadSagas'

/*
 * Collect all sagas from components here: each time a component that has a saga
 * is added, update the array.
 */

// to merge redux-form-submit-sagas into the saga middleware, add
// add the result of addFormSubmitSagaTo() to sagaMiddleware.run()
export default [
  watchMap,
  dataSaga,
  loginSaga,
  authExpiredSaga,
  logoutSaga,
  forgotPassSaga,
  resetPassSaga,
  verifyPersonSaga,
  // headerSaga,
  siteFilterSaga,
  notificationFilterSaga,
  companies,
  persons,
  subscriptions,
  addCompanySaga,
  editCompanySaga,
  addSiteSaga,
  editSiteSaga,
  invitePersonSaga,
  siteSagas,
  userAssignSaga,
  userUnassignSaga,
  userEditSaga,
  personalDataSaga,
  siteAdminSaga,
  reportSubscriptionSaga,
  ownReportSubscriptionSaga,
  fileUploadSagas,
  pollNewDataSagas,
  serverSagas,
  siteReportPickup,
  contactSagas,
  threatFilterSaga,
  reportSettingsSaga,
  reportsExecuteSaga,
  apiPackageSagas,
  apiAppsSagas,
  apiStatsSagas,
  apiStatus,
  logViewerSagas,
  supportEmailFormSagas,
  freezeSchedulerSagas,
  activateTrialCompanySaga,
  imageUploadSagas,
  onboardingSagas,
  callToActionSagas,
  bulkUserFileUploadSagas,
  addFormSubmitSagaTo(),
];

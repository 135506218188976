// to extend this file:
// * add an entry in this array, 
// * set a unique "id"
const presetFilters = [
{
    "name": "≥ 50% POP",
    "options": {
      "popSliderVal": 50,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },

      "winterTypes": ["winter", "nonwinter"]
    },
    "id": "preset-static-01",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "≥ 2″ Snow",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 20,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "winterTypes": ["winter"],
      "unit": "imperial",
    },
    "id": "preset-static-02",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "≥ 5cm Snow",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 20,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "winterTypes": ["winter"],
      "unit": "metric",
    },
    "id": "preset-static-02-metric",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "≥ Coating Ice",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "iceCoating": true,
      "winterTypes": ["winter"]
    },
    "id": "preset-static-03",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "≥ 12Hr Event",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "minDurationHours": 12,
      "winterTypes": ["winter"]
    },
    "id": "preset-static-04",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "Dmg Wind",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "damagingWind": true,
      "winterTypes": ["winter", "nonwinter"]
    },
    "id": "preset-static-05",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "Active Alerts",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "activeAlerts": true,
      "winterTypes": ["winter", "nonwinter"]
    },
    "id": "preset-static-06",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "≥ ½″ Rain",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "minRainIn": 0.5,
      "winterTypes": ["nonwinter"],
      "unit": "imperial",
    },
    "id": "preset-static-20",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "≥ 10mm Rain",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "minRainMm": 10,
      "winterTypes": ["nonwinter"],
      "unit": "metric",
    },
    "id": "preset-static-20-metric",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "Flood Risk",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "floodRisk": true,
      "winterTypes": ["nonwinter"]
    },
    "id": "preset-static-21",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "T-Storm",
    "options": {
      "popSliderVal": 0,
      "snowSliderVal": 0,
      "iceSliderVal": 0,
      "locVal": { "stateOrZipCode": "NONE" },
      "typeVal": { "IC": true, "IF": true, "IP": true, "S": true, "SW": true, "ZF": true, "ZL": true, "RS": true, "SI": true, "WM": true, "ZR": true, "BS": true, "ZY": true, "R": true, "RW": true, "F": true, "T": true, "H": true },
      "thunderStorms": true,
      "winterTypes": ["nonwinter"]
    },
    "id": "preset-static-22",
    "isQuickFilter": true,
    "filterType": "threats"
  },{
    "name": "Hurricane",
    "options": {
      "isInErrorCone": true,
      "winterTypes": ["nonwinter"]
    },
    "id": "preset-static-23",
    "isQuickFilter": true,
    "filterType": "threats",
  }
];

export default presetFilters;

import log from 'loglevel';
import { put, takeEvery, takeLatest, call, select, race, take } from 'redux-saga/effects'
import fetchApi from 'utils/fetchApi.js'
import { COMPANYDATA_SUBMIT, COMPANYDATA_SUCCESS, COMPANYDATA_FAILURE,
  COMPANY_PERSONDATA_SUBMIT, COMPANY_PERSONDATA_SUCCESS,
  COMPANY_APISUBSCRIPTIONS_SUBMIT,
  COMPANY_APISUBSCRIPTIONS_SUCCESS,
  COMPANY_APISUBSCRIPTIONS_FAILURE,
  UPDATE_WINTER_TOGGLE,
  UPDATE_UNIT_TOGGLE
} from './CompanyDuck'
import { SITES_SUBMIT, DATA_FAILURE } from 'common/dataRetrieval/DataDuck';
import { SUBSCRIPTIONDATA_SUBMIT } from 'common/dataRetrieval/SubscriptionDuck';
import { USER_REPORTSUBS_BY_COMPANY_SUBMIT } from 'common/dataRetrieval/ReportsDuck'
import { INVITEPERSON_SUCCESS } from 'Admin/InvitePerson/InvitePersonDuck'
import { MY_SITES_SUBMIT } from "./SiteDuck";
import { COMPANYPAGE_SELECT_COMPANY } from 'Admin/CompaniesManage/CompaniesManageDuck';
import { BULK_RESET } from 'Admin/BulkSiteAssignment/BulkSiteAssignmentDuck';
import { TOGGLE_LEFT, TOGGLE_RIGHT } from 'common/components/ToggleButton/ToggleButtonDuck';
import { ACTIVE_WINTER_SITES, ACTIVE_NONWINTER_SITES, UNIT_IMPERIAL, UNIT_METRIC, UNIT_IMPERIAL_DISPLAY, UNIT_METRIC_DISPLAY } from "SiteFilter/SiteFilterDuck";
import { AUTH_EXPIRED_COUNTDOWN } from "Login/LoginDuck";
import { TOGGLEBUTTON_SELECT } from "common/components/ToggleButton/ToggleButtonDuck.js";

function* fetchCompaniesCall(action){
    try{
        const login = yield select((state) => state.login);
        const isSuperAdmin = (login.roles.includes('super-admin'));

        const authToken = yield select((state) => state.login.authToken);
        // calling /Companies should only return those company records that the
        // authenticated user is allowed to see. If not, maybe we need to ask
        // for a specific company, but need to make sure in super admin companies
        // view that all companies are loaded again, not just in componentWillMount
        // const companies = yield call(fetchApi, `/Companies/${companyId?companyId:''}`, {
        const {fullFetch} = action;

        const companiesParsedJson = yield call(fetchApi, `/Companies`, {
            authToken,
            method: 'GET'
        })

        if (!companiesParsedJson.error){
          let companiesArr = companiesParsedJson

          if(isSuperAdmin){
            const companiesInfoParsedJson = yield call(fetchApi, `/Companies/allinfos`, {
              authToken,
              method: 'GET'
            });
            if(companiesInfoParsedJson?.error?.text?.authExpired) {
              yield put({ type: AUTH_EXPIRED_COUNTDOWN });
            }
            for(let c of companiesArr){
                yield put({
                    type: "COMPANYINFOS_SUCCESS",
                    companyId: c.id,
                    infos: companiesInfoParsedJson?.infosByCompanyId?.[c.id]
                })
            }
          }

          if (!Array.isArray(companiesArr)) companiesArr = [companiesParsedJson];
          for(let c of companiesArr){
            if (fullFetch){ // expensive

              if(!isSuperAdmin){
                // WW-1507: super-admins fetch /allInfos instead to get metadata for all companies
                yield put({
                  type: COMPANY_PERSONDATA_SUBMIT,
                  id: c.id
                })

                yield put({
                  type: SITES_SUBMIT,
                  companyId: c.id,
                });

                yield put({
                  type: USER_REPORTSUBS_BY_COMPANY_SUBMIT,
                  companyId: c.id
                })

                yield put({
                  type: SUBSCRIPTIONDATA_SUBMIT,
                  companyId: c.id
                })
              }
            }
          }

          yield put({
            type: COMPANYDATA_SUCCESS,
            payload : companiesArr
          });
        } else {
          yield put({ type: DATA_FAILURE,
            message: companiesParsedJson.error.text._error ? companiesParsedJson.error.text._error : companiesParsedJson.error.text
          })
        }

    } catch(error){
      log.error('fetchCompaniesCall', error)
      yield put({ type: DATA_FAILURE,
        message: 'something went wrong'
      })
    }
}

function* fetchCompanyPersonsCall({id, withReportSubscriptions}){
  try{
    const login = yield select((state) => state.login);
    const authToken = login.authToken
    let usersArr = yield call(fetchApi, `/Companies/${id}/Persons`, {
        authToken,
        method: 'GET'
    })

    if (!usersArr.error){

      const query = `?filter[where][companyId]=${id}`
      const thirdPartyUsersParsedJson = yield call(fetchApi, `/ThirdPartyUsers${query}`, {
        authToken,
        method: 'GET'
      })

      if (!thirdPartyUsersParsedJson.error){
        if (!usersArr || !usersArr.length) usersArr = []
        usersArr = usersArr.concat(thirdPartyUsersParsedJson)

        yield put({
          type: COMPANY_PERSONDATA_SUCCESS,
          personArr : usersArr,
          companyId: id
        })

        if (!login.roles.includes('super-admin'))
          yield put({
            type: MY_SITES_SUBMIT
          })

        if(withReportSubscriptions)
          yield put({
            type: USER_REPORTSUBS_BY_COMPANY_SUBMIT,
            companyId: id
          }); 
        
      } else {
        yield put({ type: DATA_FAILURE,
          message: thirdPartyUsersParsedJson.error.text._error ? thirdPartyUsersParsedJson.error.text._error : thirdPartyUsersParsedJson.error.text,
        })
      }

    } else {
      yield put({ type: DATA_FAILURE,
        message: usersArr.error.text._error ? usersArr.error.text._error : usersArr.error.text,
      })
    }
  } catch(error){
    log.error('fetchDataSaga', error)
  }
}

// super admin function to load all users in one go instead of one Ajax call
// per company
/*
function* fetchAllPersonsCall(authToken){
  try{
    let usersArr = yield call(fetchApi, '/Persons', {
        authToken,
        method: 'GET'
    })

    if (!usersArr.error){

      const thirdPartyUsersParsedJson = yield call(fetchApi, '/ThirdPartyUsers', {
        authToken,
        method: 'GET'
      })

      if (!thirdPartyUsersParsedJson.error){
        if (!usersArr || !usersArr.length) usersArr = []
        usersArr = usersArr.concat(thirdPartyUsersParsedJson)

        yield put({
          type: ALL_PERSONDATA_SUCCESS,
          personArr : usersArr,
        })

      } else {
        yield put({ type: DATA_FAILURE,
          message: thirdPartyUsersParsedJson.error.text._error ? thirdPartyUsersParsedJson.error.text._error : thirdPartyUsersParsedJson.error.text,
        })
      }

    } else {
      yield put({ type: DATA_FAILURE,
        message: usersArr.error.text._error ? usersArr.error.text._error : usersArr.error.text,
      })
    }
  } catch(error){
    log.error('fetchAllPersonsCall', error)
  }
}
*/

function* fetchCompanyPersonsAfterInviteCall({personObj}){
  if (!personObj.roles.includes('super-admin')) // super admins don't have companies
    yield put({
      type: COMPANY_PERSONDATA_SUBMIT,
      id: personObj.companyId
    })
}

function* fetchCompanyApiSubscriptions(action){

  try{
    const authToken = yield select((state) => state.login.authToken);
    const {companyId} = action

    const parsedJson = yield call(fetchApi, `/Companies/${companyId}/ApiSubscriptions`, {
      authToken,
      method: 'GET'
    })

    if (!parsedJson.error){
      yield put({
        type: COMPANY_APISUBSCRIPTIONS_SUCCESS,
        data: parsedJson
      });
    } else {
      if (!parsedJson.notfound) {
        log.error('fetchCompanyApiSubscriptions: failed to fetch API subscriptions for company', companyId, ':', parsedJson.error)
        yield put({
          type: COMPANY_APISUBSCRIPTIONS_FAILURE,
          companyId,
          message: parsedJson.error.text._error ? parsedJson.error.text._error : parsedJson.error.text
        })
        yield put({ type: AUTH_EXPIRED_COUNTDOWN });
      } else 
        yield put({
          type: COMPANY_APISUBSCRIPTIONS_SUCCESS,
          companyId,
        });
    }

  } catch(error){
    log.error('fetchCompanyApiCall', error)
  }
}

function *switchCompany(action){
  try{
    yield put({type: BULK_RESET});
  } catch (error) {
    log.error('switchCompany', error)
  }
};

function *updateWinterToggle(action){
  try{
    const subscriptions = yield select(state => state.subscriptions);

    const subscription = Object.values(subscriptions).find(subs => subs.companyId === action.companyId);

    if (!subscription) {
      // company selector is unset
      yield;
      return;
    }

    // make sure that for a company which has only one type, the winterToggle
    // has the correct corresponding value because it can't be user selected;
    // this is mostly relevant when switching between companies as a super
    // admin
    if(subscription.types.length === 1 && subscription.types[0] === 'winter')
      yield put({
        type: TOGGLEBUTTON_SELECT, 
        groupId: 'winterToggle', 
        val: TOGGLE_LEFT, 
        label: ACTIVE_WINTER_SITES
      });
    else if(subscription.types.length === 1 && subscription.types[0] === 'nonwinter')
      yield put({
        type: TOGGLEBUTTON_SELECT, 
        groupId: 'winterToggle', 
        val: TOGGLE_RIGHT, 
        label: ACTIVE_NONWINTER_SITES
      });

  } catch (error) {
    log.error('updateWinterToggle', error)
  }
};

function *updateUnitToggle(action){
  try{
    let preferredUnit = UNIT_IMPERIAL_DISPLAY;

    if(action.companyId) {
      let company = yield select(state => state.companies[action.companyId]);
      if (!company) {
        // company selector is unset
        yield put({ type: COMPANYDATA_SUBMIT });
        yield race([take (COMPANYDATA_SUCCESS), take(COMPANYDATA_FAILURE)]);
        company = yield select(state => state.companies[action.companyId]);
        if (!company) {
          yield;
          return;
        }
        // company setting
        if(company.reportUnit) preferredUnit = company.reportUnit;
      }
    } else if(action.preferredUnit) {
      // user setting
      preferredUnit = action.preferredUnit;
    }

    if(preferredUnit.toLowerCase() === UNIT_METRIC.toLowerCase())
      yield put({
        type: TOGGLEBUTTON_SELECT, 
        groupId: 'unitToggle',
        val: TOGGLE_RIGHT, 
        label: UNIT_METRIC_DISPLAY,
        value: UNIT_METRIC
      });
    else
      yield put({
        type: TOGGLEBUTTON_SELECT, 
        groupId: 'unitToggle',
        val: TOGGLE_LEFT, 
        label: UNIT_IMPERIAL_DISPLAY,
        value: UNIT_IMPERIAL
      });

  } catch (error) {
    log.error('updateUnitToggle', error)
  }
};

export default function* companySaga () {
  yield takeEvery(COMPANYDATA_SUBMIT, fetchCompaniesCall)
  yield takeEvery(COMPANY_PERSONDATA_SUBMIT, fetchCompanyPersonsCall)
  yield takeEvery(INVITEPERSON_SUCCESS, fetchCompanyPersonsAfterInviteCall)
  yield takeEvery(COMPANY_APISUBSCRIPTIONS_SUBMIT, fetchCompanyApiSubscriptions)
  yield takeLatest(COMPANYPAGE_SELECT_COMPANY, switchCompany)
  yield takeEvery(UPDATE_WINTER_TOGGLE, updateWinterToggle)
  yield takeEvery(UPDATE_UNIT_TOGGLE, updateUnitToggle)
}

import log from 'loglevel';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import fetchApi from 'utils/fetchApi';

import {
  SITE_PERSONDATA_SUBMIT,
  SITE_PERSONDATA_SUCCESS,
  COMPANY_SITEPERSONS_SUCCESS,
  MY_SITES_SUBMIT,
  MY_SITES_SUCCESS,
  MY_SITES_FAILURE
} from './SiteDuck';
import { THREATLIST_APPLY_FILTER } from 'ThreatList/ThreatListDuck';
import { TOGGLEBUTTON_SELECT } from 'common/components/ToggleButton/ToggleButtonDuck';
import { COMPANY_PERSONDATA_SUCCESS } from './CompanyDuck';
import { DATA_FAILURE, DATA_SUCCESS } from 'common/dataRetrieval/DataDuck';
import { USERASSIGN_SUCCESS } from 'Admin/SiteUsersManage/UserAssign/UserAssignDuck';
import { NOTIFICATIONLIST_APPLY_FILTER } from '../../NotificationList/NotificationListDuck';

function* fetchSitePersonsCall({ id, companyId }) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const companyPersons = yield select(state => state.companyPersons);

    let usersArr = yield call(fetchApi, `/Sites/${id}/Persons`, {
      authToken,
      method: 'GET'
    });

    const query = `?filter[where][siteId]=${id}`;
    const thirdPartyUserMappings = yield call(
      fetchApi,
      `/ThirdPartyUserSites${query}`,
      {
        authToken,
        method: 'GET'
      }
    );

    if (!usersArr.error && !thirdPartyUserMappings.error) {
      const siteUsers = {};
      getUsersFromMappings(
        siteUsers,
        thirdPartyUserMappings,
        companyPersons,
        companyId
      );
      if (siteUsers[id] && siteUsers[id].length)
        usersArr = usersArr.concat(siteUsers[id]);
      yield put({
        type: SITE_PERSONDATA_SUCCESS,
        personArr: usersArr,
        siteId: id
      });
    } else {
      if (usersArr.error)
        yield put({
          type: DATA_FAILURE,
          message: usersArr.error.text._error
            ? usersArr.error.text._error
            : usersArr.error.text
        });
      if (thirdPartyUserMappings.error)
        yield put({
          type: DATA_FAILURE,
          message: thirdPartyUserMappings.error.text._error
            ? thirdPartyUserMappings.error.text._error
            : thirdPartyUserMappings.error.text
        });
    }
  } catch (error) {
    log.error('fetchSitePersonsCall', error);
    yield put({
      type: DATA_FAILURE,
      message: 'Network error.'
    });
  }
}

const getUsersFromMappings = (
  siteUsers,
  sitePersonMappings,
  companyPersons,
  companyId
) => {
  if (!sitePersonMappings) return {};

  let pforc = companyPersons[companyId];
  if (!companyId) {
    pforc = Object.values(companyPersons).reduce((r, d) => {
      return r.concat(d);
    });
  }

  sitePersonMappings.forEach(su => {
    if (!siteUsers[su.siteId]) siteUsers[su.siteId] = [];
  });
  sitePersonMappings.filter(su => su.personId).forEach(su => {
    const person = pforc.find(cp => cp.id === su.personId);
    if (person) siteUsers[su.siteId].push(person);
  });
  sitePersonMappings.filter(su => su.thirdPartyUserId).forEach(su => {
    const user = pforc.find(cp => cp.id === su.thirdPartyUserId);
    if (user) siteUsers[su.siteId].push(user);
  });
};

function* fetchAllSitePersonsCall({ companyId }) {
  try {
    const authToken = yield select(state => state.login.authToken);
    const companyPersons = yield select(state => state.companyPersons);

    const query = `?filter[where][companyId]=${companyId}`;
    const sitePersonMappings = yield call(fetchApi, `/SitePersons${query}`, {
      authToken,
      method: 'GET'
    });
    if (sitePersonMappings.error) {
      yield put({
        type: DATA_FAILURE,
        message:
          sitePersonMappings.error.text._error ||
          sitePersonMappings.error.text.error
      });
    } else {
      const thirdPartyUserMappings = yield call(
        fetchApi,
        `/ThirdPartyUserSites${query}`,
        {
          authToken,
          method: 'GET'
        }
      );

      if (thirdPartyUserMappings.error) {
        yield put({
          type: DATA_FAILURE,
          message: thirdPartyUserMappings.error.text._error
            ? thirdPartyUserMappings.error.text._error
            : thirdPartyUserMappings.error.text
        });
      } else {
        const siteUsers = {};
        getUsersFromMappings(
          siteUsers,
          sitePersonMappings,
          companyPersons,
          companyId
        );
        getUsersFromMappings(
          siteUsers,
          thirdPartyUserMappings,
          companyPersons,
          companyId
        );

        yield put({
          type: COMPANY_SITEPERSONS_SUCCESS,
          siteUsers
        });
      }
    }
  } catch (error) {
    log.error('fetchAllSitePersonsCall error', error);
    yield put({
      type: DATA_FAILURE,
      message: 'Network error.'
    });
  }
}

function* fetchMySitesCall() {
  try {
    const login = yield select(state => state.login);
    const authToken = login.authToken;
    const userId = login.userId;

    const mySites = yield call(fetchApi, `/Persons/${userId}/sites`, {
      authToken,
      method: 'GET'
    });

    if (!mySites.error) {
      yield put({
        type: MY_SITES_SUCCESS,
        mySites
      });
    } else {
      yield put({
        type: MY_SITES_FAILURE,
        message: mySites.error.text._error
          ? mySites.error.text._error
          : mySites.error.text
      });
    }
  } catch (error) {
    log.error('fetchMySitesCall error', error);
    yield put({
      type: MY_SITES_FAILURE,
      message: 'Network error.'
    });
  }
}

function* fetchMySitesAfterDataSuccessCall({ payload }) {
  const login = yield select(state => state.login);
  if (!login.roles.includes('super-admin')) {
    yield put({
      type: MY_SITES_SUBMIT
    });
  }
}

function* fetchSitePersonsAfterUserAssignCall({ personObj }) {
  yield put({
    type: SITE_PERSONDATA_SUBMIT,
    id: personObj.siteId,
    companyId: personObj.companyId
  });
}

function* applySiteFilters({ groupId, val, label }) {
  if (groupId === 'sitefilter' || groupId === 'winterToggle') {
    yield put({ type: THREATLIST_APPLY_FILTER });
    yield put({ type: NOTIFICATIONLIST_APPLY_FILTER });
  }
}

export default function* siteSaga() {
  yield takeEvery(SITE_PERSONDATA_SUBMIT, fetchSitePersonsCall);
  yield takeEvery(COMPANY_PERSONDATA_SUCCESS, fetchAllSitePersonsCall);
  yield takeEvery(USERASSIGN_SUCCESS, fetchSitePersonsAfterUserAssignCall);
  yield takeEvery(MY_SITES_SUBMIT, fetchMySitesCall);
  yield takeEvery(DATA_SUCCESS, fetchMySitesAfterDataSuccessCall);

  yield takeEvery(TOGGLEBUTTON_SELECT, applySiteFilters);
}

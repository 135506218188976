import { idx } from 'common/helpers/helpers';
import immutable from 'object-path-immutable';
export const FILEUPLOAD_OPEN = 'BULK_FILEUPLOAD_OPEN';
export const FILEUPLOAD_CLOSE = 'BULK_FILEUPLOAD_CLOSE';
export const FILEUPLOAD_SUBMIT = 'BULK_FILEUPLOAD_SUBMIT';
export const FILEUPLOAD_SUCCESS = 'BULK_FILEUPLOAD_SUCCESS';
export const FILEUPLOAD_FAILURE = 'BULK_FILEUPLOAD_FAILURE';
export const CHANGELIST_SUBMIT = 'BULK_CHANGELIST_SUBMIT';
export const CHANGELIST_SUCCESS = 'BULK_CHANGELIST_SUCCESS';
export const CHANGELIST_FAILURE = 'BULK_CHANGELIST_FAILURE';
export const CHANGELIST_APPLY_SUBMIT = 'BULK_CHANGELIST_APPLY_SUBMIT';
export const CHANGELIST_APPLY_SUCCESS = 'BULK_CHANGELIST_APPLY_SUCCESS';
export const CHANGELIST_APPLY_FAILURE = 'BULK_CHANGELIST_APPLY_FAILURE';


export const closeUploadModal = () => ({
  type: FILEUPLOAD_CLOSE
})

export const openUploadModal = ({
  isThirdPartyUser = false
}) => ({
  type: FILEUPLOAD_OPEN,
  isThirdPartyUser
})

export const uploadFile = (file, companyId, isThirdPartyUser) => ({
  type: FILEUPLOAD_SUBMIT,
  file,
  companyId,
  isThirdPartyUser
})

export const applyChangeList = (listData, companyId, isThirdPartyUser) => ({
  type: CHANGELIST_APPLY_SUBMIT,
  listData,
  companyId,
  isThirdPartyUser
})

export const uploadChangeListFile = (file, companyId, isThirdPartyUser) => ({
  type: CHANGELIST_SUBMIT,
  file,
  companyId,
  isThirdPartyUser
})

const initialState = {
  showModal: false,
  isThirdPartyUser: false,
  meta:{
    isFetching: false,
    fileName: undefined
  },
  bulkData: {
    uploadError: undefined,
    uploadSuccess: false,
    recordsAdded: undefined
  },
  // for CSV users file to check updates/inserts/deletes/untouched:
  changeListData: {
    status: undefined,
    // map of { inserts: {count:<num>,entries:[]}, updates: {..}, deletes: {..}, untouched: {..}}
    list: undefined
  },
  applied: {
    uploadError: undefined,
    status: undefined,
    results: {}
  }
}

const ERROR_CODES = {
  NO_FILE : 'NO_FILE',
  EMPTY_FILE : 'EMPTY_FILE',
  FILE_PARSE_ERROR : 'FILE_PARSE_ERROR',
  INTERNAL : 'INTERNAL',
  NO_USER : 'NO_USER',
  NO_COMPANY : 'NO_COMPANY',
  NO_ACTIVE_SUBS : 'NO_ACTIVE_SUBS',
  NOT_AUTHORIZED : 'NOT_AUTHORIZED',
  JSON_PARSING_ERROR : 'JSON_PARSING_ERROR',
  USER_INSERTION_ERROR: 'USER_INSERTION_ERROR',
  INCORRECT_USER_DATA : 'INCORRECT_USER_DATA',
  NO_INSERT_ENTRIES : 'NO_INSERT_ENTRIES',
  NO_UPDATE_ENTRIES : 'NO_UPDATE_ENTRIES',
  UPDATE_ID_MISSING: 'UPDATE_ID_MISSING',
  UPDATE_CHANGED_FIELDS_MISSING: 'UPDATE_CHANGED_FIELDS_MISSING',
  UPDATE_CHANGED_FIELDS_ERROR: 'UPDATE_CHANGED_FIELDS_ERROR',
  NO_DELETE_ENTRIES : 'NO_DELETE_ENTRIES',
  DELETE_ID_MISSING: 'DELETE_ID_MISSING',
  USER_LIMIT_EXCEEDED: 'USER_LIMIT_EXCEEDED'
}

const handleUploadErrors = data => {
  console.log('handleUploadErrors', data)
  switch (data.code) {
    case ERROR_CODES.FILE_PARSE_ERROR:
      return {
        _error: "File parse error."
      };
    case ERROR_CODES.NO_FILE:
      return {
        _error: "File is empty or doesn't exist."
      };
    case ERROR_CODES.EMPTY_FILE:
      return {
        _error: "File is empty."
      };
    case ERROR_CODES.NO_COMPANY:
      return {
        _error: "Company doesn't exist."
      };
    case ERROR_CODES.NO_ACTIVE_SUBS:
      return {
        _error: "Company doesn't have an active subscription."
      };
    case ERROR_CODES.INCORRECT_USER_DATA:
      return {
        _error: data.invalidUserDetails
      };
    case ERROR_CODES.UPDATE_CHANGED_FIELDS_MISSING:
      return {
        _error: "Some rows have missing fields. Please check your uploaded file. " +
        `Row(s): ${data.details.map(el => el.index+1).join(', ')}`
      };
    case ERROR_CODES.UPDATE_CHANGED_FIELDS_ERROR:
      return {
        _error: "Some rows have empty fields that must not be empty. Please check your uploaded file. " +
        `Row(s): ${data.details.map(el => el.index+1).join(', ')}`
      };
    case ERROR_CODES.USER_INSERTION_ERROR:
      return {
        _error: data.insertionErrors.map((err) => ({
          index: err.user.index,
          details: idx(['details', 'messages'], err),
          user: err.user
        })),
        started: data.err
      }
    case ERROR_CODES.USER_LIMIT_EXCEEDED:
      return {
        _error: `Not enough free spaces left in Subscription for Users. 
        Subscription Limit: ${data.limit},
        Needed: ${data.subscriptionQuantityNeeded}`
      };
    default:
      return "";
  }
};

const bulkFileUpload = (state = initialState, action) => {
  switch (action.type) {
    case FILEUPLOAD_OPEN:
      return {
        ...state,
        showModal: true,
        isThirdPartyUser: action.isThirdPartyUser
      }

    case FILEUPLOAD_CLOSE:
      return initialState

    case FILEUPLOAD_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.fileName", action.file.name)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .set("changeListData", initialState.changeListData)
        .value();

    case FILEUPLOAD_SUCCESS:{
      return immutable(state)
        .set("meta.isFetching", false)
        .set("bulkData.recordsAdded", action.data.added ? action.data.added.length : undefined)
        .set("bulkData.recordsErrorsList", action.data.errors || undefined)
        .set("bulkData.started", action.data.started)
        .set("bulkData.uploadSuccess", !!action.data?.added?.length || !action.data.code)
        .set("bulkData.uploadError", handleUploadErrors(action.data))
        .value();}

    case FILEUPLOAD_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("bulkData.recordsAdded", 0)
        .set("bulkData.uploadSuccess", false)
        .set("bulkData.uploadError", action.uploadError)
        .value();

    case CHANGELIST_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("meta.fileName", action.file.name)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .set("changeListData", initialState.changeListData)
        .value();

    case CHANGELIST_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("changeListData.status", action.data.status)
        .set("changeListData.list", action.data.list)
        .set("changeListData.uploadError", handleUploadErrors(action.data))
        .value();

    case CHANGELIST_FAILURE:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("changeListData.status", undefined)
        .set("changeListData.list", undefined)
        .set("changeListData.uploadError", action.uploadError)
        .value();

    case CHANGELIST_APPLY_SUBMIT:
      return immutable(state)
        .set("meta.isFetching", true)
        .set("bulkData", initialState.bulkData)
        .set("applied", initialState.applied)
        .value();

    case CHANGELIST_APPLY_SUCCESS:
      return immutable(state)
        .set("meta.isFetching", false)
        .set("applied.status", action.data.status)
        .set("applied.results", action.data.results || {})
        .set("applied.uploadError", handleUploadErrors(action.data))
        .value();

    case CHANGELIST_APPLY_FAILURE:
      console.log('CHANGELIST_APPLY_FAILURE', action)
      const newstate = immutable(state)
        .set("meta.isFetching", false)
        .set("applied.status", ((action.data||{}).status) || 'ERROR')
        .set("applied.results", {})
        .set("applied.uploadError", action.uploadError)
        .value();
      return newstate;

    default:
      return state

  }
}

export default bulkFileUpload;

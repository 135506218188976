export const USERS_SELECT_USER = 'USERS_SELECT_USER'
export const USEREDIT_SUBMIT = 'USEREDIT_SUBMIT'
export const USEREDIT_SUCCESS = 'USEREDIT_SUCCESS'
export const USEREDIT_FAILURE = 'USEREDIT_FAILURE'

export const COMPANY_USER_REPORT_SUBMIT = 'USEREDIT_SUBMIT'
export const COMPANY_USER_REPORT_SUCCESS = 'USEREDIT_SUCCESS'
export const COMPANY_USER_REPORT_FAILURE = 'USEREDIT_FAILURE'

export const USEREDIT_OPEN = 'USEREDIT_OPEN';
export const USEREDIT_CLOSE = 'USEREDIT_CLOSE';

export const THIRDPARTYUSEREDIT_OPEN = 'THIRDPARTYUSEREDIT_OPEN';
export const THIRDPARTYUSEREDIT_CLOSE = 'THIRDPARTYUSEREDIT_CLOSE';


export const setSelectedUser = (id) => ({
  type: USERS_SELECT_USER,
  id
})

export const toggleReporting = (id, reportingOn) => {
  return {
  type: USEREDIT_SUBMIT,
  payload:{
    id,
    reportingOn
  }
}}

export const closeEditModal = () => ({
  type: USEREDIT_CLOSE
})

export const openEditModal = () => ({
  type: USEREDIT_OPEN
})

export const closeEditThirdPartyUserModal = () => ({
  type: THIRDPARTYUSEREDIT_CLOSE
})

export const openEditThirdPartyUserModal = ({editMode=false}) => ({
  type: THIRDPARTYUSEREDIT_OPEN,
  editMode
})

export const setCompanyReportForUser = (companyId, userId) => ({
  type: COMPANY_USER_REPORT_SUBMIT,
  companyId,
  userId
})

const initialState = {
  selectedUser: undefined,
  isFetching: false,
  showEditModal: false,
  showThirdPartyEditModal: false,
  thirdPartyEditMode: false
}

const usersManage = (state = initialState, action) => {
    switch(action.type){
      case USERS_SELECT_USER:
        return {
          ...state,
          selectedUser: action.id
        }

      case USEREDIT_OPEN:
        return {
          ...state,
          showEditModal: true
        }
      case USEREDIT_CLOSE:
        return {
          ...state,
          showEditModal: false,
          errorMessage: undefined
        }

      case USEREDIT_SUBMIT:
        return {
          ...state,
          errorMessage: undefined
        }

      case USEREDIT_FAILURE:
        return {
          ...state,
          errorMessage: action.message
        }

      case THIRDPARTYUSEREDIT_OPEN:
        return {
          ...state,
          showThirdPartyEditModal: true,
          thirdPartyEditMode: action.editMode
        }

      case THIRDPARTYUSEREDIT_CLOSE:
        return {
          ...state,
          showThirdPartyEditModal: false,
          errorMessage: undefined
        }

      default :
        return state;
    }
}

export default usersManage

